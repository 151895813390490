import React from "react";
import ColorPicker from "../../commons/ColorPicker";
import {
  DatePicker,
  message,
  Select,
  Alert,
  Popconfirm,
  Dropdown,
  Menu,
} from "antd";
import { connect } from "react-redux";

import {
  fbCreateGroup,
  fbGetGroup,
  fbUpdateGroup,
  fbUpdateMatchPredictionAndTickets,
  fbGetAdminsForGroup,
  fbGetGroupsForClub,
} from "../../../services/firebaseService/endPoints/group";
import LoadingModal from "../../commons/LoadingModal";
// import { triggerGroupsUpdate } from '../../../redux/actions/group';
import dayjs from "dayjs";
import { startUpdateCountry } from "../../../redux/actions/appData/country";
import { afGetSeasons } from "../../../services/apiFootballService/endPoints/seasons";
import { afGetLeagues } from "../../../services/apiFootballService/endPoints/leagues";
import { afGetClubs } from "../../../services/apiFootballService/endPoints/clubs";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import FUpload from "../../commons/formFields/FUpload";

import {
  fbRemoveAdminForGroup,
  fbCreateAdminForGroup,
  fbDemoteAdminForGroup,
  fbChangeAdminRole,
} from "../../../services/firebaseService/endPoints/authentication";
import Group from "../../../models/superAdmin/Group";
import { applicationError } from "../../../redux/actions/error";
import generateRegistrationCode from "../../../helperFunctions/generateRegistrationCode";
import { startAddGroup, startEditGroup } from "../../../redux/actions/group";
import { continents, countries } from "countries-list";
import { CirclePicker } from "react-color";
import { db } from "../../../services/firebaseService/connection";
import { rgbToHex } from "../../../helperFunctions/util";
import DemoAppView from "../../../images/demo-app-view.png";
import { collection, doc, getDoc, getDocs } from "@firebase/firestore";
import { UserAddOutlined } from "@ant-design/icons";
import { withRouter } from "../../../utils/helper";
import { ChantLogoIcon } from "../../../utils/icons.jsx";
import "./groups.css";
import ChantButton from "../../../CommonComponents/ChantButton/ChantButton";
import AppIconModal from "../../admin/groupConfig/GroupDesign/AppIconModal";

// Import app icons
import AppIcon1 from "../../../images/app-icons/1.png";
import AppIcon2 from "../../../images/app-icons/2.png";
import AppIcon3 from "../../../images/app-icons/3.png";
import AppIcon4 from "../../../images/app-icons/4.png";
import AppIcon5 from "../../../images/app-icons/5.png";
import AppIcon6 from "../../../images/app-icons/6.png";
import AppIcon7 from "../../../images/app-icons/7.png";
import AppIcon8 from "../../../images/app-icons/8.png";
import AppIcon9 from "../../../images/app-icons/9.png";
import AppIcon10 from "../../../images/app-icons/10.png";
import AppIcon11 from "../../../images/app-icons/11.png";
import AppIcon12 from "../../../images/app-icons/12.png";
import AppIcon13 from "../../../images/app-icons/13.png";
import AppIcon14 from "../../../images/app-icons/14.png";
import AppIcon15 from "../../../images/app-icons/15.png";
import AppIcon16 from "../../../images/app-icons/16.png";
import AppIcon17 from "../../../images/app-icons/17.png";
import AppIcon18 from "../../../images/app-icons/18.png";
import AppIcon19 from "../../../images/app-icons/19.png";
import AppIcon20 from "../../../images/app-icons/20.png";

const appIcons = [
  AppIcon1,
  AppIcon2,
  AppIcon3,
  AppIcon4,
  AppIcon5,
  AppIcon6,
  AppIcon7,
  AppIcon8,
  AppIcon9,
  AppIcon10,
  AppIcon11,
  AppIcon12,
  AppIcon13,
  AppIcon14,
  AppIcon15,
  AppIcon16,
  AppIcon17,
  AppIcon18,
  AppIcon19,
  AppIcon20,
];

const groupTypes = [
  "Official Supporters Group",
  "Unofficial Supporters Group",
  "Trust",
].map((type) => ({ type, key: type }));
const groupsToManage = ["All", "Official Groups", "Manual"].map((type) => ({
  type,
  key: type,
}));

const STATUS = [
  "Trial",
  "Onboarding",
  "Nurturing",
  "Active",
  "Risk",
  "Churned",
];
const OWNER = ["Abby", "Birdie", "Justin", "Tom"];

const { Option } = Select;

class SearchGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: [],
    };
  }

  render() {
    return (
      <div>
        <Select
          showSearch
          style={{ minWidth: 400 }}
          placeholder="Search group"
          onChange={(ids) => {
            let groups = ids.map(
              (id) =>
                this.props.options.find((option) => option.id === id).label
            );
            this.props.onChange(ids);
            this.setState({ children: groups });
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
          }
          mode="multiple"
          maxTagCount={3}
          defaultValue={this.props.children}
        >
          {this.props.options.map((option) => (
            <Option key={option.id} value={option.id}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

class CreateGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueList: [{ name: "Select Season First" }],
      clubList: [{ name: "Select League First" }],
      seasonList: ["Select Country First"],

      country: "",

      isLoading: false,
      loadingDocument: !!props.router.params.id,
      editMode: !!props.router.params.id,
      editConfiguration: {
        imageChanged: false,
        path: "",
        admins: [],
        prevEmail1: "",
        prevEmail2: "",
      },
      primaryColor: { r: 7, g: 26, b: 114, a: 1 },
      secondaryColor: { r: 216, g: 0, b: 255, a: 1 },
      stripesColor: { r: 0, g: 255, b: 87, a: 1 },
      textPrimaryColor: "",
      textSecondaryColor: "",
      logoList: [],
      logoFile: {},
      groupName: "",
      leagueName: "",
      leagueId: "",
      oldClubId: "",
      clubName: "",
      clubId: "",
      renewalDate: "",
      // totalMembers: "",
      registrationCode: generateRegistrationCode(),
      errorInForm: { isError: false, message: "", visible: false },

      admins: [{ name: "", email: "", phoneNumber: "", adminType: "" }],
      supportersRegion: "",
      regions: Object.keys(continents).map((key) => ({
        region: continents[key],
        key,
      })),
      supportersCountry: "",
      countries: [],
      groupType: "",
      allowPurchase: false,
      clubPostAllowed: false,
      isParent: false,
      children: [],
      isGroupSelectionManual: false,
      clubGroups: [],
      status: "",
      owner: "",
      lastMembershipCount: 0,
      paymentDetails: {
        currency: "",
        stripeAccountId: "",
      },
      colors: [
        "#000000",
        "#B80000",
        "#DB3E00",
        "#FCCB00",
        "#008B02",
        "#006B76",
        "#1273DE",
        "#004DCF",
        "#FFFFFF",
      ],
      showIconModal: false,
      selectedAppIconIndex: 6,
    };
  }

  // Form Management Functions

  pad = (num) => {
    let str = "0000" + num;
    return str.substr(str.length - 4);
  };

  createGroupIndex = async () => {
    let existingGroupIndices = await getDocs(collection(db, "group")).then(
      (snap) => {
        let groupInidices = snap.docs
          .filter((doc) => (doc.data() || {}).groupIndex)
          .map((doc) => (doc.data() || {}).groupIndex)
          .filter((index) => index);
        return groupInidices;
      }
    );

    existingGroupIndices.sort();

    let index = 1;
    for (; index < existingGroupIndices.length; index++) {
      if (parseInt(existingGroupIndices[index - 1]) !== index) {
        break;
      }
    }

    let groupIndex = this.pad(index);

    return groupIndex;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      errorInForm: {
        isError: false,
        message: "",
        visible: false,
      },
    });

    if (!this.state.logoFile.name) {
      this.setState({
        errorInForm: {
          isError: true,
          message: "Please upload a logo image for the group",
          visible: true,
        },
      });
      window.scrollTo(0, 0);
      return;
    }

    // if (this.state.admins && this.state.admins.length > 0) {

    //     let found = this.state.admins.find((admin) => {
    //         return (admin.name || admin.phoneNumber) && !validator.isEmail(admin.email)
    //     })

    //     if (found) {
    //         this.setState({
    //             errorInForm: {
    //                 isError: true,
    //                 message: "Please enter valid email for admin",
    //                 visible: true
    //             }
    //         })
    //         window.scrollTo(0, 0)
    //         return
    //     }

    // }

    this.setState({ isLoading: true });
    let data = new Group();
    data.toDataSet({ ...this.state });
    data = JSON.parse(JSON.stringify(data));

    if (!this.state.groupIndex) {
      data.groupIndex = await this.createGroupIndex();
    }
    if (data.hasError) {
      window.scrollTo(0, 0);
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: data.hasError.message,
        },
        isLoading: false,
      });
      return;
    } else {
      let promise;
      if (this.state.editMode) {
        promise = this.updateDocument(data);
      } else {
        promise = this.submitDocument(data);
      }
      promise
        .then((content) => {
          if (!this.state.editMode) {
            this.props.dispatch(startAddGroup(content));
          } else {
            this.props.dispatch(startEditGroup(content));
          }

          this.setState({ isLoading: false });
          this.props.router.navigate("/superadmin/groups", { replace: true });
          // this.props.dispatch(triggerGroupsUpdate())
        })
        .catch((err) => {
          this.setState({
            errorInForm: { isError: true, visible: true, message: err.message },
            isLoading: false,
          });
          // this.props.router.navigate("/superadmin/groups")
        });
    }
  };

  // Create a new record in the database
  submitDocument = (data) => {
    this.setState({ isLoading: true });

    return fbCreateGroup(data, this.state.logoFile, this.state.admins);
  };

  // Update existing record in the database
  updateDocument = (data) => {
    this.setState({ isLoading: true });
    return new Promise((resolve, reject) => {
      if (this.state.admins.length > 0) {
        let promises = [];
        this.state.admins.forEach((admin) => {
          if (admin.email) {
            promises.push(
              fbCreateAdminForGroup(admin.email, this.props.router.params.id, {
                name: admin.name,
                phoneNumber: admin.phoneNumber,
                type: admin.adminType,
              })
            );
          }
        });
      }

      if (this.state.oldClubId !== this.state.clubId)
        fbUpdateMatchPredictionAndTickets(
          this.props.router.params.id,
          this.state.clubId
        );

      fbUpdateGroup(
        data,
        this.props.router.params.id,
        this.state.editConfiguration.imageChanged
          ? this.state.logoFile
          : undefined
      )
        .then(resolve)
        .catch(reject);
    });
  };

  handleSendInvite = (email, index, data) => {
    const msg = message.loading(`Sending invite to ${email}`, 0);
    fbCreateAdminForGroup(email, this.props.router.params.id, {
      name: data.name,
      phoneNumber: data.phoneNumber,
    })
      .then(() => {
        msg();
        message.success(`Invite sent to ${email}`);
      })
      .catch((err) => {
        msg();
        this.setState({
          errorInForm: { isError: true, visible: true, message: err.message },
        });
        window.scrollTo(0, 0);
      });

    this.setState((prevState) => {
      let unregisteredAdmins = [...prevState.unregisteredAdmins];
      unregisteredAdmins[index].emailInviteSent = true;
      return { unregisteredAdmins };
    });
  };

  // Called whenever a new image is uploaded to deleted
  handleImageChange = ({ fileList }) => {
    let result = {};
    if (fileList.length === 0) {
      result.logoFile = {};
      if (this.state.editMode && !this.state.editConfiguration.imageChanged) {
        this.setState((prevState) => {
          let editConfiguration = Object.assign(
            {},
            prevState.editConfiguration
          );
          editConfiguration.imageChanged = true;
          return { editConfiguration };
        });
      }
    } else {
      result.logoFile = fileList[0].originFileObj;
    }
    result.logoList = fileList;
    this.setState(() => result);
  };

  // Called on form input changes
  handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  };

  // Called on form select options changes
  handleOptionChange = (e) => {
    const clubId = String(e.target.value);

    this.setState({ [e.target.name]: clubId });
    if (e.target.name === "clubId") {
      this.setState({ clubName: e.target.label });

      fbGetGroupsForClub(clubId).then((docs) => {
        this.setState({ clubGroups: docs });
      });

      getDoc(doc(db, "clubColors", clubId)).then((doc) => {
        if (doc.exists) {
          let data = doc.data() || {};
          let colors = [];
          Object.keys(data).forEach((key) => {
            if (
              key.includes("color") &&
              data[key].r !== "" &&
              data[key].g !== "" &&
              data[key].b !== ""
            ) {
              let color = data[key];
              Object.keys(color).forEach((key) => {
                if (typeof color[key] === "string") {
                  color[key] = parseInt(color[key]);
                }
              });
              let hexColor = rgbToHex(color.r, color.g, color.b);
              colors.push(hexColor);
            }
          });
          colors = Array.from(new Set([...colors, "#ffffff", "#000000"]));
          this.setState({ colors });
        }
      });
    }
  };

  //
  handleDateChange = (value) => {
    this.setState({ renewalDate: value });
  };

  /* Called on total members change and registeration code change
   *  to accept only numerical values
   */
  handleNumberChange = (e) => {
    if (!Number.isNaN(Number(e.target.value))) {
      this.setState({ [e.target.name]: Number(e.target.value) });
    }
  };

  // Called on blur of registeration code, to check for valid number of digits i.e - 6
  checkRegistrationCode = (e) => {
    if (e.target.value > 99999 && e.target.value < 1000000) {
      this.setState({
        errorInForm: { isError: false, message: "", visible: false },
      });
    } else {
      e.target.focus();
      this.setState({
        errorInForm: {
          isError: true,
          message: "Registration Code Must be Six Digit",
          visible: true,
        },
      });
    }
  };

  uploadAction = (file) => {
    this.setState(() => ({ logoFile: file }));
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = { ...prevState.errorInForm };
      errorInForm.visible = false;
      return { errorInForm };
    });
  };

  handleModalClose = () => {};

  handleColorChange = (color, type) => {
    this.setState({ [type]: color });
  };

  handleCountryChange = (selectedItem) => {
    const { value, label } = selectedItem.target;

    this.setState({
      country: value && value !== "null" ? value : label,
      seasonList: [],
      season: "",
      leagueId: "",
      leagueName: "",
      clubName: "",
      leagueList: [{ name: "Select Season First" }],
      clubId: "",
      clubList: [{ name: "Select League First" }],
    });
    afGetSeasons().then((seasons) => {
      this.setState({ seasonList: seasons });
    });
  };

  handleSeasonChange = (e) => {
    const value = e.target.value;
    this.setState({
      season: value,
      leagueList: [],
      leagueId: "",
      leagueName: "",
      clubName: "",
      clubId: "",
      clubList: [{ name: "Select League First" }],
    });

    afGetLeagues(this.state.country, value)
      .then((data) => {
        if (!data || data?.length === 0) {
          data = [{ placeholder: "No Leagues found for this country" }];
        } else {
          data = [...data];
        }

        this.setState({ leagueList: data });
      })
      .catch(() => {
        this.setState({
          leagueList: [{ placeholder: "Error loading leagues" }],
        });
      });
  };

  handleLeagueChange = (details) => {
    const { label, value } = details.target;
    this.setState({
      leagueName: label,
      leagueId: value,
      clubName: "",
      clubId: "",
      clubList: [],
    });
    afGetClubs(value, this.state.season).then((teams) => {
      if (teams) {
        this.setState({ clubList: teams });
      } else {
        this.setState({ clubList: [{ name: "Select League First" }] });
      }
    });
  };

  handleAdminChange = (name, value, index) => {
    this.setState((prevState) => {
      let admins = [...prevState.admins];
      admins[index][name] = value;
      return { admins };
    });
  };

  handleAddAdmin = () => {
    this.setState((prevState) => {
      let admins = [...prevState.admins];
      admins.push({ name: "", email: "", phoneNumber: "" });
      return { admins };
    });
  };

  handleDemoteRegisteredAdmin = (userGroupId, name) => {
    let msg = message.loading(`Demoting ${name}`, 0);
    fbDemoteAdminForGroup(this.props.router.params.id, userGroupId)
      .then(() => {
        msg();
        message.success(`Successfully demoted ${name}`);
        msg = message.loading("Updating admin lists");
        fbGetAdminsForGroup(this.props.router.params.id).then((values) => {
          msg();
          this.setState({
            registeredAdmins: values[0],
            unregisteredAdmins: values[1],
          });
        });
      })
      .catch(() => {
        msg();
        message.error(`Unable to demote ${name}, contact admin`);
      });
  };

  handleRemoveUnregisteredAdmin = (email) => {
    let msg = message.loading(`Removing ${email}`, 0);
    fbRemoveAdminForGroup(email, this.props.router.params.id)
      .then(() => {
        msg();
        message.success(`Successfully removed ${email}`);
        msg = message.loading("Updating admin lists");
        fbGetAdminsForGroup(this.props.router.params.id).then((values) => {
          msg();
          this.setState({
            registeredAdmins: values[0],
            unregisteredAdmins: values[1],
          });
        });
      })
      .catch(() => {
        msg();
        message.error(`Unable to remove ${email}, contact admin`);
      });
  };

  handleTextColorChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRegionChange = (e) => {
    let supportersRegion = e.target.value;
    let availableCountries = Object.keys(countries)
      .filter((key) => countries[key].continent === supportersRegion)
      .map((key) => ({ code: key, countryName: countries[key].name }));
    this.setState({ supportersRegion, countries: availableCountries });
  };

  handleSupportersCountryChange = (e) => {
    this.setState({ supportersCountry: e.target.value });
  };

  handleTypeChange = (e) => {
    this.setState({
      groupType: e.target.value,
    });
  };

  handleAdminTypeChange = (e, index) => {
    let adminType =
      e.target.value.toLowerCase() !== "admin" ? "parentAdmin" : "admin";
    this.handleAdminChange("adminType", adminType, index);
  };

  handleGroupsChange = (e) => {
    if (e.target.value !== "Manual") {
      this.setState({
        children: [e.target.value],
        isGroupSelectionManual: false,
      });
    } else {
      let children = this.state.editMode ? this.state.initialChildren : [];
      this.setState({ children, isGroupSelectionManual: true });
    }
  };

  handleMenuClick = (role, id, name) => {
    let msg = message.loading(`Changing role for ${name}`, 0);
    fbChangeAdminRole(role, this.props.router.params.id, id)
      .then(() => {
        msg();
        message.success(`Successfully changed role for ${name}`);
        msg = message.loading("Updating admin lists");
        fbGetAdminsForGroup(this.props.router.params.id).then((values) => {
          msg();
          this.setState({
            registeredAdmins: values[0],
            unregisteredAdmins: values[1],
          });
        });
      })
      .catch(() => {
        msg();
        message.error(`Unable to change role for ${name}, contact admin`);
      });
  };

  getImageSource = () => {
    if (this.state.editMode) {
      if (this.state.logoList[0]) {
        return this.state.logoList[0].url || this.state.preview;
      } else {
        return ChantLogoIcon;
      }
    }
    return this.state.preview || ChantLogoIcon;
  };

  toggleIconModal = () => {
    this.setState((prevState) => ({
      showIconModal: !prevState.showIconModal,
    }));
  };

  handleModalOpen = (val) => {
    this.setState({
      showIconModal: val,
    });
  };

  handleSelectIcon = (iconId) => {
    this.setState({
      selectedAppIconIndex: iconId,
    });
  };

  render() {
    const appIconImage =
      appIcons[this.state.selectedAppIconIndex] || appIcons[0];

    let colors = this.state.colors;

    let groupOptions = this.state.clubGroups.map((group) => ({
      id: group.id,
      label: group.groupName,
    }));
    if (this.state.editMode) {
      groupOptions = groupOptions.filter(
        (details) => details.id !== this.props.router.params.id
      );
    }

    const menu = ({ id, name, userRole }) => (
      <Menu onClick={({ key }) => this.handleMenuClick(key, id, name)}>
        {this.state.isParent && (
          <Menu.Item key="parentAdmin" disabled={userRole === "parentAdmin"}>
            Parent Admin
          </Menu.Item>
        )}
        <Menu.Item key="admin" disabled={userRole === "admin"}>
          Group Admin
        </Menu.Item>
        <Menu.Item key="member">Member</Menu.Item>
      </Menu>
    );

    if (!this.props.country.isCurrent)
      this.props.dispatch(startUpdateCountry());

    let loadingTitle = "",
      loadingMessage = "";
    if (this.state.loadingDocument) {
      loadingTitle = "Loading Document";
      loadingMessage = "Fetching data";
    } else if (this.state.isLoading) {
      loadingTitle = this.state.editMode ? "Updating Group" : "Creating Group";
      loadingMessage = this.state.editMode
        ? "Updating content"
        : "Creating new content";
    }

    return (
      <div
        className="mx-md-0 mx-0 border-box border rounded"
        style={{ width: "100%", overflow: "hidden", padding: "10px 70px" }}
      >
        <LoadingModal
          title={loadingTitle}
          message={loadingMessage}
          loading={this.state.loadingDocument || this.state.isLoading}
        />

        <AppIconModal
          showIconModal={this.state.showIconModal}
          handleModalOpen={this.handleModalOpen}
          handleSelectIcon={this.handleSelectIcon}
          selectedAppIconIndex={this.state.selectedAppIconIndex}
        />

        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <Alert
            message="Error"
            description={this.state.errorInForm.message}
            type="error"
            closable
            // className="mb-3"
            afterClose={this.handleErrorMessageClose}
          />
        )}
        <h6 className="mt-2" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          {this.state.editMode ? "Edit Group" : "Create Group"}
        </h6>
        <form style={{ padding: "20px 30px" }} onSubmit={this.handleSubmit}>
          <div className="form-group ">
            <div className="col-6">
              <FInput
                value={this.state.groupName}
                label="Supporters Group Name"
                onChange={this.handleInputChange}
                name="groupName"
                placeholder="Footie Mob"
                required
              />
            </div>
          </div>

          <div className="form-group">
            <div className="col-6">
              <FSelect
                label="Supporters' Group Region"
                value={this.state.supportersRegion}
                onChange={this.handleRegionChange}
                placeholder={
                  this.props.country.isCurrent
                    ? "--Select Region--"
                    : "Loading..."
                }
                dataList={this.state.regions}
                dataKey="key"
                dataValue="region"
                required
              />
            </div>

            <div className="col-6">
              <FSelect
                label="Supporters' Group Country"
                value={this.state.supportersCountry}
                onChange={this.handleSupportersCountryChange}
                placeholder={
                  this.state.countries.length > 0
                    ? "--Select Country--"
                    : "Loading..."
                }
                dataList={this.state.countries}
                dataKey="code"
                dataValue="countryName"
                required
                disabled={!this.state.supportersRegion}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="col-6">
              <FSelect
                label="Club Country"
                value={this.state.country}
                onChange={this.handleCountryChange}
                placeholder={
                  this.props.country.isCurrent
                    ? "--Select Country--"
                    : "Loading..."
                }
                dataList={this.props.country.data}
                dataKey="code"
                dataValue="name"
                required
              />
            </div>

            <div className="col-6">
              <FSelect
                label="Season"
                value={this.state.season}
                onChange={this.handleSeasonChange}
                placeholder={
                  this.state.seasonList.length === 0
                    ? "Loading..."
                    : "--Select Season--"
                }
                dataList={this.state.seasonList}
                dataKey="season"
                dataValue="season"
                dataOnlyValue
                textCenter
                required
                disabled={!this.state.country}
              />
            </div>
          </div>

          {/* League */}
          <div className="form-group">
            <div className="col-6">
              <FSelect
                label="League"
                value={this.state.leagueName}
                name="leagueId"
                onChange={this.handleLeagueChange}
                placeholder={
                  this.state?.leagueList?.length === 0
                    ? "Loading..."
                    : this.state.leagueList[0]?.placeholder
                      ? this.state.leagueList[0]?.placeholder
                      : "--Select League--"
                }
                dataList={
                  this.state.leagueList === 0
                    ? []
                    : this.state?.leagueList[0]?.placeholder
                      ? []
                      : this.state?.leagueList
                }
                dataKey="league_id"
                dataValue="name"
                required
                disabled={!this.state.season}
              />
            </div>
            <div className="col-6">
              <FSelect
                label="Club"
                value={this.state.clubName}
                name="clubId"
                onChange={this.handleOptionChange}
                placeholder={
                  this.state.clubList.length === 0
                    ? "Loading..."
                    : "--Select Club--"
                }
                dataList={this.state.clubList}
                dataKey="team_id"
                dataValue="name"
                textCenter
                required
                disabled={!this.state.leagueId}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="col-6 mb-2">
              <div className="row">
                <label className="col-4">Renewal Date</label>
                <DatePicker
                  // defaultPickerValue={this.state.renewalDate}
                  className="col-8 form-control form-control-sm"
                  value={this.state.renewalDate}
                  onChange={this.handleDateChange}
                  defaultPickerValue={dayjs().endOf("year")}
                />
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-6">
              <FInput
                label="Total Members"
                placeholder="100"
                name="totalMembers"
                value={this.state.totalMembers}
                onChange={this.handleNumberChange}
                required
              />
            </div> */}
          </div>

          {/* Logo */}
          <div className="form-group">
            <div className="col-md-6">
              <FUpload
                label="Logo"
                action={this.uploadAction}
                fileList={this.state.logoList}
                onChange={this.handleImageChange}
                aspectRatio={1 / 1}
                onLoad={(preview) => {
                  this.setState({
                    preview,
                  });
                }}
              />
            </div>

            <div className="row">
              <div className="col-md-4">
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    margin: "0",
                  }}
                >
                  App Icon (select color)
                </p>
                <p style={{ marginBottom: "6px" }}>
                  The default app icon is red. Select a color that closely
                  matches one of your club&apos;s colors. Note: If a member is
                  part of 2+ groups their app icon will switch based on group
                  last visited.
                </p>
                <ChantButton type="primary" onClick={this.toggleIconModal}>
                  Select
                </ChantButton>
              </div>

              <div className="col-md-8 ">
                {appIconImage ? (
                  <img
                    alt="app-icon"
                    height={100}
                    width={100}
                    src={appIconImage}
                  />
                ) : (
                  <div
                    style={{
                      height: 100,
                      width: 100,
                      backgroundColor: "#ccc",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Icon not found
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <FSelect
                label="Group Type"
                value={this.state.groupType}
                onChange={this.handleTypeChange}
                placeholder={!this.state.groupType && "--Select Type--"}
                dataList={groupTypes}
                dataKey="key"
                dataValue="type"
                required
              />
            </div>
            <div className="col-md-6">
              <div className="form-group" style={{ display: "flex" }}>
                <label
                  className="form-check-label ml-0"
                  htmlFor="defaultCheck1"
                >
                  Allow group to post to Global Chat Streams?&nbsp;
                </label>
                <div className="flex-center">
                  <input
                    style={{ height: "20px", width: "20px", marginLeft: 10 }}
                    type="checkbox"
                    className="form-control-input cursor-pointer"
                    id="defaultCheck1"
                    checked={this.state.clubPostAllowed}
                    onChange={(e) => {
                      this.setState({
                        clubPostAllowed: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="form-group" style={{ display: "flex" }}>
                <label
                  className="form-check-label ml-0"
                  htmlFor="defaultCheck1"
                >
                  Allow members to purchase giveaway entries?&nbsp;
                </label>
                <div className="flex-center">
                  <input
                    style={{ height: "20px", width: "20px", marginLeft: 10 }}
                    type="checkbox"
                    className="form-control-input cursor-pointer"
                    id="defaultCheck1"
                    checked={this.state.allowPurchase}
                    onChange={(e) => {
                      this.setState({
                        allowPurchase: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <div>
                <label
                  className="form-check-label ml-0"
                  htmlFor="defaultCheck2"
                >
                  Is Parent?&nbsp;
                </label>
              </div>

              <div className="flex-center">
                <input
                  style={{ height: "20px", width: "20px", marginLeft: 10 }}
                  type="checkbox"
                  className="form-control-input cursor-pointer"
                  id="defaultCheck2"
                  checked={this.state.isParent}
                  onChange={(e) => {
                    this.setState({
                      isParent: e.target.checked,
                    });
                    if (!e.target.checked) {
                      this.setState({
                        children: [],
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              <FSelect
                label="Status"
                value={this.state.status}
                onChange={(e) => {
                  this.setState({
                    status: e.target.value,
                  });
                }}
                placeholder={"Status"}
                dataList={STATUS}
                dataOnlyValue={true}
              />
            </div>
            <div className="col-6">
              <FSelect
                label="Owner"
                value={this.state.owner}
                onChange={(e) => {
                  this.setState({
                    owner: e.target.value,
                  });
                }}
                placeholder={"Owner"}
                dataList={OWNER}
                dataOnlyValue={true}
              />
            </div>
          </div>

          {this.state.isParent && (
            <div className="form-group">
              <div className="col-md-6">
                <FSelect
                  label="Child groups"
                  value={
                    this.state.isGroupSelectionManual
                      ? "Manual"
                      : this.state.children[0]
                  }
                  onChange={this.handleGroupsChange}
                  placeholder="--Select Type--"
                  dataList={groupsToManage}
                  dataKey="key"
                  dataValue="type"
                  required
                />
              </div>
              {this.state.isGroupSelectionManual && (
                <div className="col-md-5">
                  <SearchGroups
                    options={groupOptions}
                    onChange={(children) => {
                      this.setState({ children });
                    }}
                  >
                    {this.state.children}
                  </SearchGroups>
                </div>
              )}
            </div>
          )}

          <h6
            className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
            style={{ paddingLeft: 5, fontSize: "1.2rem", fontWeight: "bold" }}
          >
            App Design Settings
          </h6>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: "#cccccc",
                width: "80vw",
                padding: "5vh 3vw",
              }}
            >
              <p style={{ fontWeight: 400 }}>
                To design your app, select from the official club colors for the
                header, border and stripes or enter your own RGB color values.
                Set text values to black or white.
              </p>
              <div className="form-group row">
                <div className="col-md-6">
                  {/* Primary Club Color  */}
                  <div className="form-group">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                      style={{
                        marginLeft: "12px",
                        marginTop: "12px",
                      }}
                    >
                      Header
                    </label>
                    <div className={"col-12"}>
                      <ColorPicker
                        color={this.state.primaryColor}
                        colorType="primaryColor"
                        handleColorChange={this.handleColorChange}
                        colorOptions={colors}
                      />
                    </div>
                  </div>

                  {/* Secondary Club Color */}
                  <div className="form-group">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                    >
                      Border/Card Title
                    </label>
                    <div className={"col-12"}>
                      <ColorPicker
                        color={this.state.secondaryColor}
                        colorType="secondaryColor"
                        handleColorChange={this.handleColorChange}
                        colorOptions={colors}
                      />
                    </div>
                  </div>

                  {/* Strips Color */}
                  <div className="form-group">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                    >
                      Stripes
                    </label>
                    <div className={"col-12"}>
                      <ColorPicker
                        color={this.state.stripesColor}
                        colorType="stripesColor"
                        handleColorChange={this.handleColorChange}
                        colorOptions={colors}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                      style={{ fontFamily: "Open Sans" }}
                    >
                      Chant
                    </label>
                    <CirclePicker
                      colors={["#000000", "#FFFFFF"]}
                      width="220px"
                      triangle={"hide"}
                      onChange={(val) => {
                        this.setState({
                          textPrimaryColor: val.hex,
                        });
                      }}
                      color={this.state.textPrimaryColor}
                    />
                  </div>

                  <div className="form-group row">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                    >
                      Card Titles
                    </label>
                    <CirclePicker
                      colors={["#000000", "#FFFFFF"]}
                      width="220px"
                      triangle={"hide"}
                      onChange={(val) => {
                        this.setState({
                          textSecondaryColor: val.hex,
                        });
                      }}
                      color={this.state.textSecondaryColor}
                    />
                  </div>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 30,
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      height: "81%",
                      backgroundColor: "#000000",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        flex: "0 0 26%",
                        backgroundColor: `rgba(${this.state.primaryColor.r}, ${this.state.primaryColor.g}, ${this.state.primaryColor.b}, 1)`,
                        borderBottom: `7px solid rgba(${this.state.secondaryColor.r},${this.state.secondaryColor.g},${this.state.secondaryColor.b},1)`,
                        position: "relative",
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#ffffff",
                          width: 150,
                          height: 150,
                          borderRadius: "50%",
                          border: `7px solid rgba(${this.state.secondaryColor.r}, ${this.state.secondaryColor.g}, ${this.state.secondaryColor.b}, 1)`,
                          position: "absolute",
                          left: "50%",
                          top: "10%",
                          transform: "translateX(-50%)",
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={this.getImageSource()}
                          alt="logo"
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          flex: "0 0 5%",
                          backgroundColor: `rgba(${this.state.stripesColor.r}, ${this.state.stripesColor.g}, ${this.state.stripesColor.b}, 1)`,
                          marginRight: "5%",
                        }}
                      ></div>
                      <div
                        style={{
                          flex: "0 0 5%",
                          backgroundColor: `rgba(${this.state.stripesColor.r}, ${this.state.stripesColor.g}, ${this.state.stripesColor.b}, 1)`,
                          marginRight: "5%",
                        }}
                      ></div>
                      <span
                        style={{
                          color: this.state.textPrimaryColor,
                          fontSize: "30px",
                          alignSelf: "flex-end",
                          marginRight: "auto",
                          marginLeft: "15px",
                          fontFamily: "monospace",
                          fontWeight: "bold",
                        }}
                      >
                        Chant
                      </span>
                    </div>
                    <div
                      style={{
                        flex: "0 0 60%",
                        backgroundColor: "#ffffff",
                        marginTop: 80,
                        width: "98%",
                        alignSelf: "center",
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        padding: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            color: this.state.textSecondaryColor,
                            backgroundColor: `rgba(${this.state.secondaryColor.r},${this.state.secondaryColor.g},${this.state.secondaryColor.b},1)`,
                            fontFamily: "Open Sans",
                            fontWeight: "bolder",
                            borderRadius: 3,
                            padding: "2px 5px",
                          }}
                        >
                          Var Poll
                        </span>
                        <i
                          className="fa fa-angle-up"
                          style={{
                            fontSize: 20,
                            color: "#6ac4f1",
                            fontWeight: "bolder",
                          }}
                        />
                      </div>
                      <hr />
                      <div>
                        <img
                          src={DemoAppView}
                          style={{ width: "100%" }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Renewal Date */}

          {this.state.admins.map((element, index) => {
            return (
              <div key={index} className="form-group">
                <div className="col-md-6">
                  <FInput
                    label={`Admin-${index + 1} Name`}
                    onChange={(e) => {
                      this.handleAdminChange("name", e.target.value, index);
                    }}
                    value={this.state.admins[index].name}
                    required={!!this.state.admins[index].email}
                  />
                </div>
                <div className="col-md-6">
                  <FInput
                    label="Email"
                    onChange={(e) => {
                      this.handleAdminChange("email", e.target.value, index);
                    }}
                    value={this.state.admins[index].email}
                    textCenter
                    required={
                      !!this.state.admins[index].name ||
                      !!this.state.admins[index].phoneNumber
                    }
                  />
                </div>
                <div className="col-md-6">
                  <FInput
                    label="Phone"
                    onChange={(e) => {
                      this.handleAdminChange(
                        "phoneNumber",
                        e.target.value,
                        index
                      );
                    }}
                    value={this.state.admins[index].phoneNumber}
                    textCenter
                    required={!!this.state.admins[index].email}
                  />
                </div>
                <div className="col-md-6">
                  <FSelect
                    label="Role"
                    value={
                      this.state.admins[index].adminType &&
                      (this.state.admins[index].adminType !== "admin"
                        ? "Parent Admin"
                        : "Admin")
                    }
                    onChange={(e) => {
                      this.handleAdminTypeChange(e, index);
                    }}
                    placeholder={this.state.adminType || "--Select Type--"}
                    dataList={`${this.state.isParent || this.state.groupType.toLowerCase() === "club" ? "Parent Admin," : ""}Admin`
                      .split(",")
                      .map((type) => {
                        return { key: type, type };
                      })}
                    dataKey="key"
                    dataValue="type"
                    required
                  />
                </div>
              </div>
            );
          })}
          <div className="mb-4">
            <span
              className={`btn btn-sm btn-info`}
              onClick={this.handleAddAdmin}
            >
              Add Admin <UserAddOutlined />
            </span>
          </div>

          {this.state.editMode && (
            <div className="row mt-2">
              <div className="col-lg-6">
                <h6>UnRegistered Admins</h6>
                {this.state.unregisteredAdmins ? (
                  this.state.unregisteredAdmins.length === 0 ? (
                    <p>No unregistered admin found</p>
                  ) : (
                    this.state.unregisteredAdmins.map((admin, index) => {
                      return (
                        <div className="row" key={admin.id || index}>
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control my-2"
                              value={admin.id}
                            />
                          </div>
                          <div className="my-auto mx-2">
                            <Popconfirm
                              title="Are you sure ?"
                              onConfirm={() => {
                                if (!admin.emailInviteSent) {
                                  this.handleSendInvite(admin.id, index, {
                                    name: admin.name,
                                    phoneNumber: admin.phoneNumber,
                                  });
                                }
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <span
                                className={`btn btn-sm ${
                                  admin.emailInviteSent
                                    ? "btn-secondary disabled"
                                    : "btn-danger"
                                }`}
                              >
                                {admin.emailInviteSent
                                  ? "Invite Sent"
                                  : "Send Invite"}
                              </span>
                            </Popconfirm>
                          </div>
                          <div className="my-auto">
                            <Popconfirm
                              title="Are you sure ?"
                              onConfirm={() => {
                                this.handleRemoveUnregisteredAdmin(admin.id);
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <span className={`btn btn-sm btn-danger`}>
                                Remove
                              </span>
                            </Popconfirm>
                          </div>
                        </div>
                      );
                    })
                  )
                ) : (
                  <p>Unable to fetch details</p>
                )}
              </div>

              <div className="col-lg-6">
                <h6>Registered Admins</h6>
                {this.state.registeredAdmins ? (
                  this.state.registeredAdmins.length === 0 ? (
                    <p>No registered admin found</p>
                  ) : (
                    this.state.registeredAdmins.map((admin) => {
                      return (
                        <div className="row" key={admin.id || admin.name}>
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control my-2"
                              value={`${admin.name}`}
                            />
                          </div>

                          <div className="my-auto mx-2">
                            <Dropdown overlay={menu(admin)}>
                              <span className={`btn btn-sm btn-danger`}>
                                Action
                              </span>
                            </Dropdown>
                          </div>
                        </div>
                      );
                    })
                  )
                ) : (
                  <p>Unable to fetch details</p>
                )}
              </div>
            </div>
          )}

          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {this.state.editMode ? "Update Group" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    );
  }

  handleEditMode = async () => {
    let group = new Group();
    let data;

    // if (this.props.group) {
    //     data = this.props.group

    // } else {
    data = await fbGetGroup(this.props.router.params.id);
    // }
    group.fromDataSet(data);
    group.groupIndex = data.groupIndex;
    if (group.hasError) {
      this.props.router.navigate("/superadmin/groups");
    } else {
      Promise.all([
        afGetSeasons(),
        afGetLeagues(group.country, group.season),
        afGetClubs(group.leagueId, group.season),
        fbGetAdminsForGroup(this.props.router.params.id),
        fbGetGroupsForClub(group.clubId),
        getDoc(doc(db, "clubColors", group.clubId)).then((doc) => {
          if (doc.exists) {
            let data = doc.data() || {};
            let colors = [];
            Object.keys(data).forEach((key) => {
              if (
                key.includes("color") &&
                data[key].r !== "" &&
                data[key].g !== "" &&
                data[key].b !== ""
              ) {
                let color = data[key];
                Object.keys(color).forEach((key) => {
                  if (typeof color[key] === "string") {
                    color[key] = parseInt(color[key]);
                  }
                });
                let hexColor = rgbToHex(color.r, color.g, color.b);
                colors.push(hexColor);
              }
            });
            colors = Array.from(new Set([...colors, "#ffffff", "#000000"]));
            this.setState({ colors });
          }
        }),
      ])
        .then((values) => {
          group.seasonList = values[0];
          group.leagueList = values[1];
          group.clubList = values[2];
          group.registeredAdmins = values[3][0];
          group.unregisteredAdmins = values[3][1];
          group.admins = [];
          group.countries = Object.keys(countries)
            .filter((key) => countries[key].continent === data.supportersRegion)
            .map((key) => ({ code: key, countryName: countries[key].name }));
          group.clubGroups = values[4];
          this.setState({
            ...group,
            loadingDocument: false,
            oldClubId: group.clubId,
            initialChildren:
              group.children.includes("All") ||
              group.children.includes("Official Groups")
                ? []
                : group.children,
          });
        })
        .catch(() => {
          this.props.dispatch(
            applicationError({
              message: "Unable to details from football api",
              show: true,
              intensity: "high",
            })
          );
          this.props.router.navigate("/superadmin/groups", { replace: true });
        });
    }
  };

  // updateGroupIndicesForAllGroups = async () => {
  //   let snaps = await db.collection('group').get().then(snap => snap.docs);

  //   for (let i = 0; i < snaps.length; i++) {
  //     if (!snaps[i].data().groupIndex) {
  //       const groupIndex = await this.createGroupIndex();
  //       await snaps[i].ref.set({ groupIndex }, { merge: true });
  //     }
  //   }
  // }

  componentDidMount() {
    // Checking for edit mode.
    if (this.props.router.params.id) {
      this.handleEditMode();
    }
  }
}

const mapStateToProps = (state) => {
  let result = {};
  // if (props.match.params.id) {
  //     result.group = state.groups.data.find((group) => {
  //         return group.id === props.match.params.id
  //     })
  // }

  result.country = state.country;
  return result;
};

export default connect(mapStateToProps)(withRouter(CreateGroup));
